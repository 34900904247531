<template>
  <div class="zadi-books-page">
    <page-header title="كنوز زادي" />
    <infinite-scroll-wrapper @fetch-data="fetchAllBooks" :limit="limit" :total="total"
      :is-loading="isLoadingBooks">
      <template>
        <book-summary-mini v-for="book in books" :key="book.id" :book="book" />
      </template>
      <template v-if="isLoadingBooks">
        <book-summary-mini-skelton v-for="n in limit" :key="n" />
      </template>
    </infinite-scroll-wrapper>
  </div>
</template>

<script>
import PageHeader from "../../website-components/zadi/shared/PageHeader.vue";
import BookSummaryMini from "../../website-components/zadi/books/BookSummaryMini.vue";
import BookSummaryMiniSkelton from "../../website-components/zadi/books/BookSummaryMiniSkelton.vue";
import InfiniteScrollWrapper from "../../website-components/zadi/shared/InfiniteScrollWrapper.vue";
import zadiRepository from "../../repositories/zadiRepository";

export default {
  metaInfo: {
    title: "مشروع زادي | كنوز زادي ",
  },
  components: { BookSummaryMini, BookSummaryMiniSkelton, PageHeader, InfiniteScrollWrapper },
  data() {
    return {
      books: [],
      isLoadingBooks: false,
      page: 1,
      limit: 4,
      total: null
    }
  },
  methods: {
    async fetchAllBooks({ page, limit }) {
      this.isLoadingBooks = true;
      const res = await zadiRepository.getAllBooks(page, limit);
      this.books = [...this.books, ...res?.data?.data];
      this.total = res?.data?.pageCount;
      this.isLoadingBooks = false;
    },
    navigate() {
      this.$router.push({ name: 'ZadiBookDetails', params: { id: 1, } });
    },
  },
  async mounted() {
    await this.fetchAllBooks({
      page: this.page,
      limit: this.limit,
    })
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");

.zadi-books-page {
  padding: 120px 40px 80px 40px;
  font-family: "Tajawal", sans-serif;
}

@media (max-width: 560px) {
  .zadi-books-page {
    padding: 120px 20px 80px 20px;
  }
}
</style>
