<template>
  <div class="book-summary-mini">
    <div class="row">
      <div class="col-lg-3">
        <div class="image">
          <img :src="book?.imageUrl" />
        </div>
      </div>
      <div class="col-lg-9">
        <div class="info">
          <p>{{ book?.title }}</p>
          <p>{{ book?.author }}</p>
        </div>
        <div class="details">
          <p>{{ book?.description }}</p>
        </div>
        <div class="actions">
          <button class="read-more" @click="openBookDetails()">
            <img src="../../../../../../public/assets/images/website/zadi/book.png" />
            إقرأ المزيد
          </button>
          <button class="open-video" @click="openBookVideo()" v-if="book.summeryVideoUrl">
            <img src="../../../../../../public/assets/images/website/zadi/video.png" />
            مشاهدة الفيديو
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    book: {
      type: Object,
      default: () => null,
    },
  },
  methods: {
    openBookDetails() {
      const id = this.book.id
      this.$router.push({ name: 'ZadiBookDetails', params: { id: id } })
    },
    openBookVideo() {
      const link = this.book.summeryVideoUrl
      window.open(link, '_blank').focus()
    }
  },
};
</script>

<style scoped>
.book-summary-mini {
  width: 100%;
  min-height: 201px;
  border-radius: 25px;
  border: 2px solid #86ccd4;
  padding: 15px;
  overflow: hidden;
  margin-bottom: 25px;
  box-shadow: 3px 9px 8px rgba(106, 106, 106, 16%);
}

.book-summary-mini:last-child {
  margin-bottom: 0px;
}

.row {
  overflow: hidden;
  height: 100%;
}

.col-lg-9,
.col-lg-3 {
  overflow: hidden;
}

.col-lg-9 {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.image {
  height: 165px;
}

.image img {
  border-radius: 16px;
  border: 2px solid #86ccd4;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  font-weight: 500;
  font-size: 17px;
  display: flex;
  align-items: center;
  column-gap: 15px;
  text-align: center;
}

.info p:first-child {
  font-weight: 600;
  color: #86ccd4;
  font-size: 22px;
}

.info p:last-child {
  font-weight: 500;
  font-size: 18px;
  color: #f4acbf;
}

.details {
  flex: 1;
}

.details p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  font-weight: 500;
  font-size: 14px;
  text-align: justify;
}

.actions {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.actions button {
  border-radius: 10px;
  color: #ffffff;
  padding: 6px;
  font-size: 15px;
  width: 170px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
}

.actions button img {
  width: 25px;
}

.actions button.read-more {
  background: #f4acbf;
}

.actions button.read-more:hover {
  background: #f58da9;
}

.actions button.open-video {
  background: #9DD7D3;
}

.actions button.open-video:hover {
  background: #6ed6cf;
}

@media (max-width: 992px) {
  .col-lg-3 {
    padding-bottom: 16px;
  }

  .image {
    height: 200px;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }

  .actions {
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
  }
}
</style>